import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "We provide solutions for the transportation industry",
    paragraph:
      "We provide a wide range of fleet management software solutions such as ELD, driver and asset tracking and maintenance data but also custom solutions to fit our customers' needs.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={splitClasses}>
            <div className='split-item'>
              <div
                className='split-item-content center-content-mobile reveal-from-left'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  ELD Complaince
                </div>
                <h3 className='mt-0 mb-12'>Nationwide ELD</h3>
                <p className='m-0'>
                  The best ELD experience for you and you employees.
                  <br />
                  <br />
                  <b>Nationwide ELD</b> offers more than just Hours of Service
                  and ELD complaince. You can easily track your drivers,
                  generate IFTA reports and your drivers can easily use it to
                  complete eDVIR.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container='.split-item'
              >
                <Image
                  src={require("./../../assets/images/features-eld.png")}
                  alt='Features split 01'
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className='split-item'>
              <div
                className='split-item-content center-content-mobile reveal-from-right'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  Asset Tracking
                </div>
                <h3 className='mt-0 mb-12'>Nationwide LiveTrak</h3>
                <p className='m-0'>
                  Wether you are looking to track your trucks, trailer or
                  drivers, we have the solution for you.
                  <br />
                  <br />
                  <b>Nationwide LiveTrak</b> lets you view the current location
                  and travel history of your assets with high accuracy and
                  precision.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container='.split-item'
              >
                <Image
                  src={require("./../../assets/images/features-livetrak.png")}
                  alt='Features split 02'
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className='split-item'>
              <div
                className='split-item-content center-content-mobile reveal-from-left'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  Custom Solutions
                </div>
                <h3 className='mt-0 mb-12'>Custom technology solutions</h3>
                <p className='m-0'>
                  We can provide you with custom software and hardware solutions
                  using cutting edge technologies to meet your needs.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container='.split-item'
              >
                {/* <Image
                  src={require("./../../assets/images/features-split-image-03.png")}
                  alt='Features split 03'
                  width={528}
                  height={396}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
