import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import ContactDetails from "../components/sections/ContactDetails";
import FeaturesSplitPartners from "../components/sections/FeaturesSplitPartners";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import Testimonial from "../components/sections/Testimonial";
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
import CtaOg from "../components/sections/CtaOg";

const ReturnPolicy = () => {
  return (
    <div className="jumbotron-fluid">
      <div className="container-fluid">
        <div className="row">
          <div class="col-md-2 col-sm-2 col-xs-2"></div>
          <div class="col-md-8 col-sm-8 col-xs-8 marTop30px">
            <h1>
              <strong>RETURN AND EXCHANGE POLICY</strong>
            </h1>
            Last Updated: 01/01/2023
            <p>
              Thank you for shopping at <strong>https://nwt.ai</strong>
            </p>
            <p>
              If, for any reason, You are not completely satisfied with a
              purchase, we invite You to review our Policy.{" "}
            </p>
            <p>
              The following terms are applicable for any products that you
              purchased with us.
            </p>
            <br />
            <h2>
              <strong>INTERPRETATION AND DEFINITIONS</strong>
            </h2>
            <pre> </pre>
            <h3>
              <strong>Interpretation</strong>
            </h3>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h3>
              <strong>Definitions</strong>
            </h3>
            <p>For the purposes of this Return and Exchange Policy:</p>
            <p>
              <strong>Business Company</strong> (referred to as either "the
              Company", "We", "Us" or "Our" in this Agreement) refers to{" "}
              <strong>Nationwide Technologies</strong> Located at{" "}
              <strong>3684 W Uva Ln, San Bernardino, CA 92407</strong>,{" "}
              <strong>California</strong>, <strong>US</strong>.
            </p>
            <p>
              <strong>Goods</strong> refer to the items offered for sale on the
              Service.
            </p>
            <p>
              <strong>Orders</strong> mean a request by You to purchase Goods
              from Us.
            </p>
            <p>
              <strong>Service</strong> refers to the Website.
            </p>
            <p>
              <strong>Website</strong> refers to My Site, accessible from{" "}
              <strong>https://nwt.ai</strong>
            </p>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
            <br />
            <h2>
              <strong>GENERAL RETURN POLICY</strong>
            </h2>
            <p>
              We want you to be happy with your purchase. If you are not
              completely satisfied, you can return the product to us and we will
              either repair/replace it, or credit your account. You are eligible
              for a<strong> REFUND or EXCHANGE</strong> which are subject to the
              below terms:
            </p>
            <p>
              Products that are bought from the{" "}
              <strong>Physical Stores can be returned. </strong>All Items are
              eligible for return exempt the products that are:{" "}
              <strong>
                Shipping Fees and Monthly Service Fees are not refundable.
              </strong>
            </p>
            <p>
              You are always required to ship back the items by following the
              shipping instruction:
            </p>
            <p>
              <strong>
                If applicable we will send you a shipping label once the return
                request has been approved. In case you need to ship the products
                using any carrier, please use our main business address. Please
                make sure to include the return slip in the package. You may
                also choose to drop the items off in person at our business
                address at no additional cost.
              </strong>
            </p>
            <br />
            <h2>
              <strong>REFUND POLICY</strong>
            </h2>
            <p>
              In order to be eligible for a refund, you have to return the goods
              within <strong>14</strong> days of your purchase. If the product
              is damaged in any way, or you have initiated the return after{" "}
              <strong>14</strong> days have passed, you will not be eligible for
              a refund.
            </p>
            <ul>
              <li>
                <strong>
                  Product must be returned in its original packaging
                </strong>
              </li>
              <li>
                <strong>Product wasn't used or damaged</strong>
              </li>
              <li>
                <strong>
                  Product must include original tags (if applicable)
                </strong>
              </li>
            </ul>
            <p>
              Aside from these conditions there are items that can be returned
              but are not refundable as stated in the following:
            </p>
            <p>
              <strong></strong>
            </p>
            <p>
              After we receive your product, our team of professionals will
              inspect it and process your refund. The money will be refunded to
              the <strong>Original Payment Method</strong> you’ve used during
              the purchase.{" "}
            </p>
            <h2>
              <strong>EXCHANGE POLICY</strong>
            </h2>
            <p>
              Product can be exchanged for a different size or color variation,
              provided that such variation is available. Customers are allowed
              to exchange for item within <strong>Always </strong>days.
              Exchanges exceeding <strong>Always </strong>days will not be
              processed.{" "}
            </p>
            <p>
              In order to be eligible for exchanges make sure that these
              following conditions are met:
            </p>
            <ul>
              <li>
                <strong>
                  Product must be returned in its original packaging
                </strong>
              </li>
            </ul>
            <p>
              You are required to ship back the product if
              <strong>
                {" "}
                In case you prefer to return the item in person at our business
                address, no shipping charges will be deducted..
              </strong>
            </p>
            <p>
              You exchanged product will be processed{" "}
              <strong>Once items are received and conditions confirmed</strong>.
            </p>
            We only exchange items if they are faulty, and have not been damaged
            by the customer..
            <h3>
              <strong>HOW TO INITIATE A RETURN</strong>
            </h3>
            <p>
              If you have a request for Return, Refund or Exchange and if you
              have further clarification and questions, Please do not hesitate
              to contact us through our:
            </p>
            <p>
              Email Address:{" "}
              <span style={{ color: "#000080" }}>
                <strong>support@nwt.ai</strong>
              </span>
            </p>
            <p>
              Phone Number:{" "}
              <span style={{ color: "#000080" }}>
                <strong>+1 909 340 2770</strong>
              </span>
            </p>
            <p>
              You will be updated for their Return Status through their
              <strong> PHONE</strong> provided that contact information is
              recorded to us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnPolicy;
