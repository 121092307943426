import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import ContactDetails from "../components/sections/ContactDetails";
import FeaturesSplitPartners from "../components/sections/FeaturesSplitPartners";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import Testimonial from "../components/sections/Testimonial";
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
import CtaOg from "../components/sections/CtaOg";

const Partners = () => {
  return (
    <>
      <FeaturesSplitPartners
        invertMobile
        topDivider
        imageFill
        className='illustration-section-02'
      />
    </>
  );
};

export default Partners;
