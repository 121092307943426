import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplitPartners = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Partner list",
    paragraph: "",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={splitClasses}>
            <div className='split-item'>
              <div
                className='split-item-content center-content-mobile reveal-from-left'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  Supply Chain Visibility & Planning
                </div>
                <h3
                  className='mt-0 mb-12'
                  onClick={() =>
                    (window.location.href = "https://www.truckertools.com/")
                  }
                >
                  Trucker Tools
                </h3>
                <p className='m-0'>
                  <b>Trucker Tools</b> provides load visibility, predictive
                  freight matching and trip planning solutions for the
                  transportation industry.
                  <br />
                  <br />
                  <b>Trucker Tools</b>’ platform gives brokers accurate,
                  real-time data optimally matching freight with trucks and
                  allowing them to track loads from the beginning of the trip to
                  the end. Our popular driver app, downloaded by over 1.2
                  million drivers, provides automated, real-time shipment
                  tracking to eliminate check calls.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container='.split-item'
              >
                <Image
                  src={require("./../../assets/images/TT-Logos-For-FW_3600x1350.png")}
                  link='https://www.truckertools.com/'
                  onClick={() =>
                    (window.location.href = "https://www.truckertools.com/")
                  }
                  alt='Features split 01'
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className='split-item'>
              <div
                className='split-item-content center-content-mobile reveal-from-right'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  Supply Chain Visibility
                </div>
                <h3 className='mt-0 mb-12'>Coming Soon</h3>
                <p className='m-0'></p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container='.split-item'
              >
                <Image
                  src={require("./../../assets/images/placeholder-logo-empty.png")}
                  alt='Features split 02'
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplitPartners.propTypes = propTypes;
FeaturesSplitPartners.defaultProps = defaultProps;

export default FeaturesSplitPartners;
